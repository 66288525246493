import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { HomeOutlined, DoneOutlineRounded, AccountTreeRounded, GroupAddOutlined, GroupRounded, AllInclusiveOutlined, GroupWorkOutlined, EmojiEmotionsOutlined } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Lo que hacemos y ofrecemos</h2>
          <h5 className={classes.description}>
            Con un trabajo en el campo por más de 50 años, nos especializamos en la producción y comercialización de Gyr lechero y sus cruces.
            Estamos ubicados a 6 km de la ciudad de Montería a orillas del río Sinú, uno de los valles más fértiles del mundo.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Selección de cruces"
              description="Usamos toros Holstein y vacas Gyr de rendimiento y genética reconocida. "
              icon={AccountTreeRounded}
              iconColor="info"
              vertical
            />
          </GridItem>
      
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Transferencia embrionaria"
              description="Trabajamos con In Vitro la empresa lider en el país en la ejecución de proyectos de mejoramiento genético bovino."
              icon={GroupWorkOutlined}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Registro Asocebú"
              description="Genética garantizada avalada por La Asociación Colombiana de Criadores de Ganado Cebú."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Toros, novillas, preñeces y donadoras. "
              description="Resultado: la mejor genética en producción lechera."
              icon={EmojiEmotionsOutlined}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
